// Responsiveness
function toggleSidebarMobileClass(root_elem) {
  var win = $(window);
  if (win.width() < 1250)
    $('.app-container').toggleClass('closed-sidebar-mobile closed-sidebar');
  else
    $('.app-container').removeClass('closed-sidebar-mobile closed-sidebar');
}

document.addEventListener("turbolinks:load", () => {
  // Bootstrap initializers
  $('[data-toggle="tooltip"]').tooltip()
  $('.alert').alert()

  $('.mobile-toggle-nav').click(function () {
    $(this).toggleClass('is-active');
    $('.app-container').toggleClass('sidebar-mobile-open');
  });

  $('.mobile-toggle-header-nav').click(function () {
    $(this).toggleClass('active');
    $('.app-header__content').toggleClass('header-mobile-open');
  });

  $(function () {
    $('[data-toggle="popover"]').popover({
      html: true
    })
  })

  // other initializers
  $(".metismenu").metisMenu();

  const PerfectScrollbar = require('perfect-scrollbar').default;
  if ($('.scrollbar-sidebar').length) {
    const ps = new PerfectScrollbar('.scrollbar-sidebar');
  }

  toggleSidebarMobileClass();

  $.blockUI.defaults.message = $('.page-loader');
  $.blockUI.defaults.css = { border: 'none', color: '#fff' };
  $.blockUI.defaults.baseZ = 10000;

  $(document).on('submit','.search-form form',function(){
    $.blockUI();
  });

  $(".search-form .autosubmit").change(function () {
    $(this).closest('form').submit();
  });

  $('.modal').appendTo("body");

  // move to shared/simple_calendar.js
  $('.calendar-modal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget)
    var modal = $(this)
    modal.find('.date').html(button.data('date'))
    modal.find('#date').val(button.data('date'))
    modal.find('#start_date').val(button.data('date'))
  })

  // draggable modals
  $('.modal-header').on('mousedown', function(mousedownEvt) {
    var $draggable = $(this);
    var x = mousedownEvt.pageX - $draggable.offset().left,
        y = mousedownEvt.pageY - $draggable.offset().top;
    $('body').on('mousemove.draggable', function(mousemoveEvt) {
      $draggable.closest('.modal-content').offset({
        'left': mousemoveEvt.pageX - x,
        'top': mousemoveEvt.pageY - y
      });
    });
    $('body').one('mouseup', function() {
      $('body').off('mousemove.draggable');
    });
    $draggable.closest('.modal').one('bs.modal.hide', function() {
      $('body').off('mousemove.draggable');
    });
  });

  // Production Panel - highlight input field
  $('.operation-options').on('shown.bs.collapse', function () {
    $(this).find('input.form-control').first().focus()
  })

  markOnClick();

  window.$('.selectable').select2();

  $(document).on('cocoon:after-insert', function() {
    $('.selectable').select2({
      placeholder: '',
      allowClear: true,
      language: 'pl'
    });

    markOnClick();
  });

  setTimeout(function () {
    $('#debt-lock-banner').fadeIn(600);
  }, 3000);
  $('#debt-lock-banner button.close').on('click', function() {
    $(this).closest('#debt-lock-banner').fadeOut(600);
  })

  countLiveWorktimeTimer();

  $("#filter-operations").on('keyup', function(){
    var matcher = new RegExp($(this).val(), 'i');
    $('.operations .operation').show().not(function(){
      return matcher.test($(this).find('.operation-name').text())
    }).hide();
    $('.tasks .task').show().not(function(){
      return matcher.test($(this).find('.task-name').text())
    }).hide();
  });

  // tasks - close modal after save (workaround due to update by TasksReflex#assign)
  $('.task-save').click(function() {
    $(this).closest('.modal').modal('hide');
  });
  // tasks - apply select2 and datepicker to fields created by TasksReflex
  $(document).on('shown.bs.modal', function (event) {
    $(event.target).find('.selectable').select2({
      dropdownParent: $(event.target),
      language: 'pl'
    });
    $(event.target).find('[data-toggle="datepicker"]').datepicker({
      language: "pl-PL",
      format: "YYYY-mm-dd",
      autoHide: true
    });
  })
});

$(() => {
  $(window).on('resize', function(){
    toggleSidebarMobileClass();
  });

  onScan.attachTo(document, {
    suffixKeyCodes: [13],
    minLength: 8,
    keyCodeMapper: function(keyboardEvent) {
      if (keyboardEvent.key == '-') // pass hyphen
        return keyboardEvent.key;
      else if (!isNaN(parseInt(keyboardEvent.key))) // pass only numbers
        return onScan.decodeKeyEvent(keyboardEvent);
      else
        return onScan.decodeKeyEvent(keyboardEvent);
    }
  });

  disableNumberInputsScrolling();
});

function markOnClick() {
  $('.mark-on-click').on('click', function() {
    $(this).select();
  });
};

// Disable scroll for all number-type inputs
function disableNumberInputsScrolling() {
  document.querySelectorAll('input[type=number]').forEach(numberInput => {
    numberInput.addEventListener('focus', (event) => {
      event.target.addEventListener('wheel.disableScroll', (e) => {
        e.preventDefault()
      })
    });
    numberInput.addEventListener('blur', (event) => {
      event.target.removeEventListener('wheel.disableScroll', null);
    });
  });
}

function countLiveWorktimeTimer() {
  $('.ongoing-live-timer').each(function( index ) {
    let start = new Date($(this).data('started-at'));
    let now;
    setInterval(() => {
      now = new Date();
      seconds = Math.floor((now - (start))/1000);
      minutes = Math.floor(seconds/60);
      hours = Math.floor(minutes/60);
      days = Math.floor(hours/24);

      hours = hours-(days*24);
      minutes = minutes-(days*24*60)-(hours*60);
      seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
      $(this).html(hours + ':' + String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0'));
    }, 1000);
  });
}
